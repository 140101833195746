<template>
  <div class="item">
    <el-row class="header">
      <el-col :span="6" class="text-center">商品信息</el-col>
      <el-col :span="2" class="text-center">单价</el-col>
      <el-col :span="2" class="text-center">数量</el-col>
      <el-col :span="5" class="text-center">单品优惠券</el-col>
      <el-col :span="5" class="text-center">单品促销</el-col>
      <el-col :span="2" class="text-right">单品促销折扣</el-col>
      <el-col :span="2" class="text-right">总金额</el-col>
    </el-row>
    <el-row class="main">
      <div class="shopInfo">
        <GoodsItem v-for="(item, index) in goodsList.orderDetails" :key="index"  :num="index" :goodsDetails="item" @GoodsItemFun="amountFun">
        </GoodsItem>
      </div>
      <!-- couponListManageVOS -->
      <div class="shopInfo">
        <GiftItem v-for="(item, index) in goodsList.couponListManageVOS" :key="index" :goodsDetails="item" ></GiftItem>
      </div>
      <!-- <el-col :span="24" class="complimentary">
        <el-col :span="12" class="remarkBox">
          <div class="leftRemark">
            备注：
          </div>
          <div class="rightRemark">
            <el-input
              type="textarea"
              v-model="goodsList.note"
              placeholder="请输入您要留言的内容"
              resize="none"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="12" class="specialOfferBox">
          <div class="topSpecialOffer">
              <span class="desTit">品类/多品优惠券:</span>
              <div class="desCenter">
                  已为您选择最优
              </div>
              <span class="desEnd">-￥{{moreAmount}}</span>
          </div>
          <div class="bottomSpecialOffer">
              <span class="desTit">订单优惠券:</span>
              <div class="desCenter">
                已为您选择最优
                  //<el-select  v-model="ordereCouponSelected" clearable placeholder="请选择"  @change="ordereCouponFun">
                    //      <el-option
                    //       v-for="item in goodsList.couponList"
                    //      :key="item.id"
                    //     :label="item.couponName"
                    //     :value="{ couponId: item.couponId, label: item.name ,amount:item.amount}"
                    //   >
                    //   </el-option>
                  // </el-select>
              </div>
              <span class="desEnd">-￥{{orderAmount}}</span>
          </div>
        </el-col>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
const GoodsItem = () => import("components/order/GoodsItem.vue");
// GiftItem
const GiftItem = () => import("components/order/GiftItem.vue");
export default {
  name: "CommodityList",
  data() {
    return {
      // ordereCouponSelected: "",
      // orderAmount:0,
      // moreAmount:0
    };
  },
  props:{
    goodsList:{
       type:Object
    }
  },
  created() {
    
    console.log(this.goodsList)
    //  this.orderAmount=this.goodsList.couponAmount
    //  this.moreAmount=this.goodsList.specCouponAmount
    //如果没有这句代码，订单优惠券select中初始化会是空白的，默认选中就无法实现
    // this.ordereCouponSelected = this.goodsList.couponList[0].couponName;
    //  this.orderAmount=this.goodsList.couponList[0].amount
  },
  computed: {},
  methods: {
    //订单优惠券勾选  传值给父组件
    // ordereCouponFun(params){
    //   if(this.goodsList.allowCoupon==0){
    //          this.ordereCouponSelected=""
    //         this.$message({
    //               message:'该订单不可使用优惠券',
    //               type: "warning",
    //             });
    //          this.orderAmount=0;
    //   }else{
    //     if(params.amount==undefined){
    //         this.orderAmount=0
    //     }else{
    //         this.orderAmount=params.amount
    //     }
    //     this.$emit("OrderFun",params)
    //     //select选择后赋值
    //     let couponList=this.goodsList.couponList;
    //     for(let i=0;i<couponList.length;i++){
    //       if(couponList[i].couponId==params.couponId){
    //         this.ordereCouponSelected = couponList[i].couponName;
    //       }
    //     }
    //   }
    // },
    // 单个商品勾选优惠券 传值
    amountFun(data){
        this.$emit("ItemFun",data)
    }
  },
  components: {
    GoodsItem,
    GiftItem
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.item {
  padding: 0 20px 20px 20px;
  .header {
    padding: 20px;
    background: #f6f6f6;
    color: #666666;
    font-size: 13px;
  }
  .main {
    width: 100%;
    .shopInfo{
      clear: both;
    }
    // .complimentary {
    //   height: 120px;
    //   border: 1px solid #f0f0f0;
    //   .remarkBox {
    //     padding: 20px;
    //     border-right: 1px solid #f0f0f0;
    //     height: 120px;
    //     .leftRemark {
    //       font-size: 12px;
    //       color: #666;
    //       font-weight: bold;
    //       width: 40px;
    //       float: left;
    //     }
    //     .rightRemark {
    //       font-size: 12px;
    //       color: #999;
    //       width: calc(100% - 40px);
    //       background: #fff;
    //       height: 80px;
    //       float: left;
    //       /deep/.el-textarea {
    //         width: 100%;
    //         height: 100%;
    //         .el-textarea__inner {
    //           width: 100%;
    //           height: 100%;
    //           border: 1px solid #f0f0f0;
    //           padding: 10px;
    //         }
    //       }
    //     }
    //   }
    //   .specialOfferBox {
    //     height:60px;
    //     .topSpecialOffer {
    //       height:40px;
    //       padding: 20px 20px  0;
    //       .desTit{
    //           float: left;
    //           width: 100px;
    //           text-align: right;
    //           margin-right: 25px;
    //           line-height:40px;
    //           font-size: 12px;
    //           color: #666;
    //       }
    //       .desCenter{
    //             float: left;
    //           width: calc(100% - 225px);
    //           font-size: 12px;
    //           color: #666;
    //           line-height:40px;
    //           /deep/.el-select {
    //               height: 30px;
    //               width: 175px;
    //               line-height: 30px;
    //               .el-input__inner {
    //               height: 30px;
    //               }
    //               .el-input__icon {
    //               line-height: 30px;
    //               }
    //           }
    //       }
    //       .desEnd{
    //           float: left;
    //           width:100px;
    //           text-align: right;
    //           line-height:40px;
    //           font-size: 14px;
    //           color: #333;
    //       }
    //     }
    //     .bottomSpecialOffer {
    //       height:50px;
    //       padding: 0 20px;
    //       .desTit{
    //           float: left;
    //           width: 100px;
    //           text-align: right;
    //           margin-right: 25px;
    //           line-height:50px;
    //           font-size: 12px;
    //           color: #666;
    //       }
    //       .desCenter{
    //             float: left;
    //           width: calc(100% - 225px);
    //           font-size: 12px;
    //           color: #666;
    //           line-height:50px;
    //           /deep/.el-select {
    //               height: 30px;
    //               width: 175px;
    //               line-height: 30px;
    //               .el-input__inner {
    //               height: 30px;
    //               }
    //               .el-input__icon {
    //               line-height: 30px;
    //               }
    //           }
    //       }
    //       .desEnd{
    //           float: left;
    //           width:100px;
    //           text-align: right;
    //           line-height:50px;
    //           font-size: 14px;
    //           color: #333;
    //       }
    //     }
    //   }
    // }
    .payMethod {
      border: 1px solid #ccc;
      padding: 10px;
    }
  }
}
</style>
